* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased; /* Chrome, Safari */
    -moz-osx-font-smoothing: grayscale; /* Firefox */
}

body {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol";
    background-color: #111314;
    color: #fff;
    margin: 0 224px;
    /* font-size: 16px; */
}

.navigation {
    padding: 20px 0 56px 0;
}

.nav__wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.logo2 {
    width: 48px;
}

.logo {
    cursor: pointer;
}

hr.separator {
    border: 0.1px solid rgba(255, 255, 255, 0.08);
}

header {
    padding-bottom: 40px;
}

.lang {
    text-decoration: none;
    border: none;
    color: #fff;
    background-color: #ffffff00;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.1s;
}

.lang:hover {
    /* text-decoration: none; */
    color: #ffffff8d;
}

h1 {
    font-weight: 800;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -1px;
    color: #ffffff;
}

@media (max-width: 1366px) {
    body {
        margin: 16px;
    }

    .navigation {
        margin-bottom: 40px;
        padding: 8px 0;
    }

    .header {
        padding: 4px 0 32px 0;
    }

    h1 {
        font-size: 56px;
        line-height: 60px;
    }
}

@media screen and (max-width: 413px) and (min-width: 377px) {
    h1 {
        font-size: 52px;
        line-height: 56px;
    }
}

@media (max-width: 376px) {
    h1 {
        font-size: 48px;
        line-height: 56px;
    }
}

@media (max-width: 321px) {
    h1 {
        font-size: 43px;
        line-height: 48px;
    }
}

.card-container {
    /* max-width: 100%;  */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 16px;
}

.card {
    background: #1e2124;
    /* border: 1px solid rgba(255, 255, 255, 0.06); */
    border-radius: 12px;
    padding: 24px 20px;
    cursor: pointer;
    transition: opacity 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translateZ(0);
}

.card:hover {
    background: #22262a;
    transition: 0.2s;
}

.card:active {
    transform: scale(0.99);
}

.card h2 {
    margin: 10px 0;
    font-size: 22px;
    font-weight: 800;
}

.card p {
    font-size: 1em;
    line-height: 22px;
    color: #9099a2;
}

.emoji {
    font-size: 24px;
}

.emoji2 {
    font-size: 40px;
    margin-bottom: 16px;
}

@media (max-width: 480px) {
    .card-container {
        grid-template-columns: 1fr;
        margin: 0;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
    /* backdrop-filter: blur(2px); */
}

@keyframes popupAnimation {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.8);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

.popup {
    transform: translate(-50%, -50%);
    position: fixed;
    max-width: 100%;
    top: 50%;
    left: 50%;
    background: rgb(26, 28, 31);
    z-index: 1001;
    /* border: 1px solid rgba(255, 255, 255, 0.08); */
    border-radius: 12px;
    padding: 56px;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.3);
    animation: popupAnimation 0.05s ease-in-out forwards;
}

.overlay.closing {
    animation: fadeOut 0.3s ease-in-out;
}

.popup.closing {
    animation: slideOut 0.1s ease-in-out;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes slideOut {
    0% {
        transform: translate(-50%, -50%);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(0.9);
        opacity: 0;
    }
}

.popup-content {
    width: 100%;
}

.popup-header {
    width: 100%;
    margin-bottom: 48px;
}

.popup-body {
    margin-bottom: 48px;
}

.popup-body h3 {
    font-size: 26px;
    font-weight: 800;
    margin-bottom: 16px;
}

.popup h2 {
    font-size: 38px;
    margin-bottom: 16px;
    font-weight: 800;
}

.popup p {
    font-size: 18px;
    line-height: 26px;
}

.popup a {
    display: block;
    margin: 10px 0;
}

.popup button {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 24px;
    right: 24px;
    text-decoration: none;
    border: none;
    background-color: rgb(42, 45, 48);
    border-radius: 50px;
    font-family: inherit;
    padding: 8px;
    cursor: pointer;
    transition: all 0.1s;
}

.popup button:hover {
    background-color: rgb(59, 64, 68);
    border-radius: 50px;
}

.popup button:active {
    transform: scale(0.96);
}

.wiki {
    display: flex;
    flex-direction: row;
    align-items: center;
	transition: all 0.2s;
}

.wiki img {
    margin-top: 2px;
    margin-left: 2px;
}

.link {
    color: #ffffff;
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    transition: all 0.1s;
}

.link:hover {
    color: #ffffff8d;
}

.link:visited {
    color: #DB6DED;
    border-bottom: 1px solid rgba(220, 109, 237, 0.6);
}

@media screen and (max-width: 900px) {
    .popup {
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding: 40px 16px;
        overflow-x: hidden;
    }

    .popup h2 {
        margin-bottom: 12px;
        font-size: 32px;
    }

    .popup-body h3 {
        margin-bottom: 12px;
        font-size: 24px;
    }

    .popup p {
        font-size: 16px;
        line-height: 24px;
    }

    .popup button {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 16px;
        right: 16px;
        text-decoration: none;
        border: none;
        background-color: rgb(42, 45, 48);
        border-radius: 50px;
        font-family: inherit;
        padding: 8px;
        transition: all 0.1s;
    }
}

.tabs-wrapper {
    display: flex;
    margin: 0 -16px;
}

.tabs-wrapper > * {
    padding: 0 16px 20px 16px;
}

.tabs-container {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 8px;
    -webkit-overflow-scrolling: touch;
}

.tab {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #1e2124;
    transition: all 0.1s;
}

.tab:hover {
    background-color: #292e32;
}

.tab:active {
    transform: scale(0.96);
}

.tab.active {
    background-color: #ffffff;
    color: #111314;
}

/* Стили для preloader */
.loader {
    position: absolute;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3c3c3c;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.5s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* .hero {
    display: inline-block;
}

.layers:hover {
    position: relative;
}

.layers:hover::before,
.layers:hover::after {
    content: attr(data-text);
    position: absolute;
    width: 110%;
    z-index: -1;
}

.layers:hover::before {
    top: 10px;
    left: 1px;
    color: #e0287d;
}

.layers:hover::after {
    top: 5px;
    left: -10px;
    color: #1bc7fb;
}

@keyframes paths {
    0% {
        clip-path: polygon(
            0% 43%,
            83% 43%,
            83% 22%,
            23% 22%,
            23% 24%,
            91% 24%,
            91% 26%,
            18% 26%,
            18% 83%,
            29% 83%,
            29% 17%,
            41% 17%,
            41% 39%,
            18% 39%,
            18% 82%,
            54% 82%,
            54% 88%,
            19% 88%,
            19% 4%,
            39% 4%,
            39% 14%,
            76% 14%,
            76% 52%,
            23% 52%,
            23% 35%,
            19% 35%,
            19% 8%,
            36% 8%,
            36% 31%,
            73% 31%,
            73% 16%,
            1% 16%,
            1% 56%,
            50% 56%,
            50% 8%
        );
    }

    5% {
        clip-path: polygon(
            0% 29%,
            44% 29%,
            44% 83%,
            94% 83%,
            94% 56%,
            11% 56%,
            11% 64%,
            94% 64%,
            94% 70%,
            88% 70%,
            88% 32%,
            18% 32%,
            18% 96%,
            10% 96%,
            10% 62%,
            9% 62%,
            9% 84%,
            68% 84%,
            68% 50%,
            52% 50%,
            52% 55%,
            35% 55%,
            35% 87%,
            25% 87%,
            25% 39%,
            15% 39%,
            15% 88%,
            52% 88%
        );
    }

    30% {
        clip-path: polygon(
            0% 53%,
            93% 53%,
            93% 62%,
            68% 62%,
            68% 37%,
            97% 37%,
            97% 89%,
            13% 89%,
            13% 45%,
            51% 45%,
            51% 88%,
            17% 88%,
            17% 54%,
            81% 54%,
            81% 75%,
            79% 75%,
            79% 76%,
            38% 76%,
            38% 28%,
            61% 28%,
            61% 12%,
            55% 12%,
            55% 62%,
            68% 62%,
            68% 51%,
            0% 51%,
            0% 92%,
            63% 92%,
            63% 4%,
            65% 4%
        );
    }

    45% {
        clip-path: polygon(
            0% 33%,
            2% 33%,
            2% 69%,
            58% 69%,
            58% 94%,
            55% 94%,
            55% 25%,
            33% 25%,
            33% 85%,
            16% 85%,
            16% 19%,
            5% 19%,
            5% 20%,
            79% 20%,
            79% 96%,
            93% 96%,
            93% 50%,
            5% 50%,
            5% 74%,
            55% 74%,
            55% 57%,
            96% 57%,
            96% 59%,
            87% 59%,
            87% 65%,
            82% 65%,
            82% 39%,
            63% 39%,
            63% 92%,
            4% 92%,
            4% 36%,
            24% 36%,
            24% 70%,
            1% 70%,
            1% 43%,
            15% 43%,
            15% 28%,
            23% 28%,
            23% 71%,
            90% 71%,
            90% 86%,
            97% 86%,
            97% 1%,
            60% 1%,
            60% 67%,
            71% 67%,
            71% 91%,
            17% 91%,
            17% 14%,
            39% 14%,
            39% 30%,
            58% 30%,
            58% 11%,
            52% 11%,
            52% 83%,
            68% 83%
        );
    }

    76% {
        clip-path: polygon(
            0% 26%,
            15% 26%,
            15% 73%,
            72% 73%,
            72% 70%,
            77% 70%,
            77% 75%,
            8% 75%,
            8% 42%,
            4% 42%,
            4% 61%,
            17% 61%,
            17% 12%,
            26% 12%,
            26% 63%,
            73% 63%,
            73% 43%,
            90% 43%,
            90% 67%,
            50% 67%,
            50% 41%,
            42% 41%,
            42% 46%,
            50% 46%,
            50% 84%,
            96% 84%,
            96% 78%,
            49% 78%,
            49% 25%,
            63% 25%,
            63% 14%
        );
    }

    90% {
        clip-path: polygon(
            0% 41%,
            13% 41%,
            13% 6%,
            87% 6%,
            87% 93%,
            10% 93%,
            10% 13%,
            89% 13%,
            89% 6%,
            3% 6%,
            3% 8%,
            16% 8%,
            16% 79%,
            0% 79%,
            0% 99%,
            92% 99%,
            92% 90%,
            5% 90%,
            5% 60%,
            0% 60%,
            0% 48%,
            89% 48%,
            89% 13%,
            80% 13%,
            80% 43%,
            95% 43%,
            95% 19%,
            80% 19%,
            80% 85%,
            38% 85%,
            38% 62%
        );
    }

    1%,
    7%,
    33%,
    47%,
    78%,
    93% {
        clip-path: none;
    }
}

@keyframes opacity {
    0% {
        opacity: 0.1;
    }

    5% {
        opacity: 0.7;
    }

    30% {
        opacity: 0.4;
    }

    45% {
        opacity: 0.6;
    }

    76% {
        opacity: 0.4;
    }

    90% {
        opacity: 0.8;
    }

    1%,
    7%,
    33%,
    47%,
    78%,
    93% {
        opacity: 0;
    }
}

.font {
    animation: font 7s step-end infinite;
}

.glitch:hover span {
    animation: paths 5s step-end infinite;
}

.glitch:hover::before {
    animation: paths 10s step-end infinite, opacity 12s step-end infinite,
        font 14s step-end infinite, movement 16s step-end infinite;
}

.glitch:hover::after {
    animation: paths 10s step-end infinite, opacity 12s step-end infinite,
        font 15s step-end infinite, movement 16s step-end infinite;
} */

.footer {
	padding: 56px 0 40px 0;
	display: flex;
    justify-content: space-between;
}

.footer a {
	color: #fff;
	text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    transition: all 0.1s;
}

.footer a:hover {
    color: #ffffff8d;
}

.version {
    color: #9099A2;
}

@media (hover: none) {
    .lang:hover {
        color: #fff;
    }

    .card:hover {
        background: #1e2124;
    }

    .popup button:hover {
        background-color: rgb(42, 45, 48);
    }

    .link:hover {
        color: #ffffff;
    }

    .footer a:hover {
        color: #fff;
    }
}